<template>
  <tj-dialog
    v-model="visible"
    title="分佣比例设置"
    width="300px"
    top="40vh"
    center
    @close="onClose"
  >
    <el-form label-width="auto" :model="form">
      <el-form-item label="上门费：">
        <el-input-number
          v-model.number="doorRatio"
          :min="0"
          :max="100"
          :step="10"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="服务费：">
        <el-input-number
          v-model.number="serviceRatio"
          :min="0"
          :max="100"
          :step="10"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="配件费：">
        <el-input-number
          v-model.number="partRatio"
          :min="0"
          :max="100"
          :step="10"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { defaults } from 'lodash';
import { ref, computed } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

const defaultRatioInfo = {
  id: 0,
  door_ratio: 0,
  service_ratio: 0,
  part_ratio: 0,
};

const form = ref({ ...defaultRatioInfo });

const doorRatio = computed({
  get() {
    const re = form.value.door_ratio;
    if (re) {
      return re * 100;
    }
    return 0;
  },
  set(val) {
    form.value.door_ratio = val / 100;
  },
});

const serviceRatio = computed({
  get() {
    const re = form.value.service_ratio;
    if (re) {
      return re * 100;
    }
    return 0;
  },
  set(val) {
    form.value.service_ratio = val / 100;
  },
});

const partRatio = computed({
  get() {
    const re = form.value.part_ratio;
    if (re) {
      return re * 100;
    }
    return 0;
  },
  set(val) {
    form.value.part_ratio = val / 100;
  },
});

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((data) => {
  form.value = defaults({ ...data }, { ...defaultRatioInfo });
});
function onClose() {
  form.value = { ...defaultRatioInfo };
}

function submit() {
  const {
    id,
    door_ratio: door,
    service_ratio: service,
    part_ratio: part,
  } = form.value;
  request.post(`/admin/engineer/setRatio/${id}`, {
    door_ratio: door,
    service_ratio: service,
    part_ratio: part,
  }).then(() => {
    emit('submit');
    close();
  });
}

defineExpose({
  open,
});
</script>
